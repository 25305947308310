<template>  
<div>
  <br>  
  <div class="logo-centered"></div>

  <div class="w560">
    <div class="w300" v-if="!token && !verified">
      <h1><span class="checkmark"></span>Check your email</h1>
      <p>We've emailed you a link to <b>{{email ? email : '(email address missing)'}}</b> that you can use to confirm your email address.</p>
      <!-- <button class="insead large" @click="$router.push('/login')">Back to login screen</button> -->
    </div>

    <div class="w300" v-if="token && token != 'expired' && email && !verified">
      <h1><span class="checkmark"></span>Verifying...</h1>
      <p>Checking your account <b>{{email}}</b>, please wait for a few seconds.</p>
      <!--  -->
    </div>

    <div class="w300" v-if="verified">
      <h1>Email verified{{verified == 200 ? '!' : ', but...'}}</h1>
      <p v-if="verified == 200">Your email is verified and now you can start using your account. Click on the button below to login.</p> 
      <p v-if="verified == 202">Your email is now verified, but we couldn't find your organization.<br><br> An administrator will review your account and get back to you. This can take 1 - 2 days.</p> 
      <br>
      <button class="insead large" @click="$router.push('/login')">Go to login screen</button>
    </div> 

    <div class="w300" v-if="token == 'expired'">
      <h1>Confirm your email</h1>
      <p><b>{{email ? email : '(email address missing)'}}</b></p>
      <p>If you didn't receive the confirmation email after registration, or the link has expired (it is valid for 24 hours), you can resend it by clicking the button below.
        <br>
        <br>
        Please also check your spam folder in case it ended up there.
      </p> 
      <br>
      <button class="insead large" @click="requestVerify" :disabled="loading">Resend confirmation email</button>
    </div> 

    <span class="spinner" v-if="spinner" />
    <div class="formerror" v-if="error">{{error}}</div>
  </div>
</div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'EmailConfirm',
  data: function(){
    return {
      email: '',
      token: '',     
      error: '',
      verified: undefined,
      loading: false
    }
  },
  computed: {   
    spinner: function() { return this.loading || this.$store.getters.loading; }
  },
  methods: {  
    async verify() {    
      try{ 
        this.error = ''
        this.loading = true;
        let resp = await axios.post(`auth/confirmemail?email=${encodeURIComponent(this.email)}&token=${encodeURIComponent(this.token)}`)        
        this.verified = resp.status        
      }
      catch(err){
        this.error = err.response ? err.response.data : "Something went wrong, please try again or contact us.";
      }
      finally{
        this.loading = false;
      }
    },  
    async requestVerify() {    
      try{ 
        this.error = ''
        this.loading = true;
        await axios.post(`auth/requestconfirmemail?email=${encodeURIComponent(this.email)}`) 
        this.token = null
      }
      catch(err){
        this.error = err.response ? err.response.data : "Something went wrong, please try again or contact us.";
      }
      finally{
        this.loading = false;
      }
    },  
    tryParseToken() {    
      this.email = this.$route.query?.email
      this.token = this.$route.query?.token      
    } 
  },
  components: {
  },
  mounted: async function() {
    this.tryParseToken()
    if(this.email && this.token && this.token != 'expired')
      await this.verify()
  }
}
</script>

<style lang="scss">

</style>